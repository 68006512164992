import React from "react"

const Footer = () => {
  return(
    <div className='footer'>
      <div className='footer-text'>
        <p>Daniel Pifer - All Rights Reserved &copy;2019</p>
      </div>
    </div>
  )
}

export default Footer